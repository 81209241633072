<template>
  <spinner
    v-if="initialDataStatus === 'loading'"
    preset="large"
  />

  <template v-else-if="initialDataStatus === 'loaded'">
    <template v-if="initialData.subscription_status === 'not_subscribed'">
      <div
        v-if="mode === 'settings'"
        class="introduction"
      >
        <p>Subscribe today for these great benefits:</p>

        <ul>
          <li>Promote yourself with a professional gamer profile</li>
          <li>Join, create, and manage teams</li>
          <li>Use calendars</li>
          <li>Become a coach or find a coach</li>
          <li>Update your feed and follow other users</li>
        </ul>
      </div>

      <p class="text-center">
        <template v-if="initialData.ipg_status === 'not_ipg'">
          A subscription is just
          ${{ formatCurrency(initialData.pricing.customer_subscription) }}
          per month. If you register to become an Independent Professional
          Gamer for only
          ${{ formatCurrency(initialData.pricing.ipg_registration) }} then
          the cost of your subscription will be reduced to just
          ${{ formatCurrency(initialData.pricing.customer_subscription_reduced) }}
          per month!
        </template>

        <template v-else>
          Since you are an Independent Professional Gamer, your subscription
          will be just
          ${{ formatCurrency(initialData.pricing.customer_subscription_reduced) }}
          per month!
        </template>
      </p>
    </template>

    <template v-else>
      <table>
        <tr>
          <th>Subscription status</th>
          <td :class="{ 'red-text': initialData.subscription_status === 'inactive' }">
            {{ capitalizeWords(initialData.subscription_status) }}

            <template
              v-if="
                initialData.subscription_status === 'inactive'
                  && initialData.subscription_status_timestamp !== undefined
              "
            >
              as of
              {{ timestampDisplay(initialData.subscription_status_timestamp) }}
            </template>
          </td>
        </tr>
        <tr v-if="initialData.next_billing_timestamp">
          <th>Next billing date/time</th>
          <td>{{ timestampDisplay(initialData.next_billing_timestamp) }}</td>
        </tr>
        <tr>
          <th>Price</th>
          <td>
            <template v-if="initialData.ipg_status === 'not_ipg'">
              ${{ formatCurrency(initialData.pricing.customer_subscription) }}
              per month

              <button
                type="button"
                class="btn btn-primary btn-xs"
                @click="promptToBecomeIPG"
              >
                Get a Discount
              </button>
            </template>

            <template v-else>
              ${{ formatCurrency(initialData.pricing.customer_subscription_reduced) }}
              per month
            </template>
          </td>
        </tr>
      </table>

      <p
        v-if="initialData.subscription_status === 'active'"
        class="text-center"
      >
        To change your payment method or cancel your subscription, please use
        PayPal's website.

        <span class="red-text">
          Please note that if you cancel your subscription, you will
          immediately lose access (i.e. you will not get to keep using
          The Players Arena for the remainder of this billing period).
        </span>
      </p>
    </template>

    <div
      v-if="initialData.subscription_status !== 'active'"
      class="paypal-content"
    >
      <p v-if="payPalSubscriptionsScriptStatus === 'loaded'">
        Please use the PayPal button below to

        <template v-if="initialData.subscription_status === 'not_subscribed'">
          subscribe.
        </template>

        <template v-else>
          renew your subscription.
        </template>
      </p>

      <div id="paypal-button-container" />

      <div
        ref="spinnerTemplate"
        class="spinner-template"
      >
        <spinner preset="large" />
      </div>
    </div>

    <spinner
      v-if="payPalSubscriptionsScriptStatus === 'loading'"
      preset="large"
    />

    <template v-else-if="payPalSubscriptionsScriptStatus === 'error'">
      <alert variant="danger">
        An error occurred communicating with PayPal. Please check your
        connection and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="$store.dispatch('loadPayPalSubscriptionsScript')"
      >
        Try Again
      </button>
    </template>

    <div
      v-if="mode === 'onboard'"
      class="text-center"
    >
      <button
        type="button"
        class="btn btn-outline-light"
        @click="$emit('back')"
      >
        Back
      </button>
    </div>
  </template>

  <template v-else-if="initialDataStatus === 'error'">
    <alert variant="danger">
      An error occurred loading your data. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadInitialData"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick } from 'vue';
import { mapState } from 'vuex';
import { SubscriptionDataInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  emits: [
    'back',
  ],
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    initialData: {} as SubscriptionDataInterface,
  }),
  computed: {
    ...mapState([
      'payPalSubscriptionsScriptStatus',
    ]),
  },
  watch: {
    payPalSubscriptionsScriptStatus() {
      if (this.payPalSubscriptionsScriptStatus === 'loaded') {
        this.createPayPalButton();
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    createPayPalButton() {
      let planId = '';

      if (this.initialData.ipg_status === 'not_ipg') {
        planId = process.env.VUE_APP_PAYPAL_SUBSCRIPTION_PLAN_ID_CUSTOMER;
      } else {
        planId = process.env.VUE_APP_PAYPAL_SUBSCRIPTION_PLAN_ID_IPG;
      }

      window.paypal.Buttons({
        createSubscription: (data: any, actions: any) => actions.subscription.create({
          plan_id: planId,
          custom_id: this.userData.id,
        }),
        onApprove: () => {
          this.$swal.fire({
            title: 'Almost Done!',
            html: '<p>Please allow 90 seconds for PayPal to confirm your '
              + "subscription status with The Players Arena. Once that's "
              + 'complete, you will be able to create posts, create or join '
              + 'teams, let others know what games you play, and more!</p>'
              + '<p>This window will disappear after 90 seconds.</p>'
              + `${(this.$refs.spinnerTemplate as HTMLDivElement).innerHTML}`,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 90000,
            willClose: () => {
              const userData = JSON.parse(JSON.stringify(this.userData));
              delete userData.signup_data;
              userData.subscription_status = 'active';
              this.$store.commit('userData', userData);

              if (this.mode === 'settings') {
                this.loadInitialData();
              }
            },
          });
        },
      }).render('#paypal-button-container');
    },
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_subscription/',
      });

      if (responseData.status === 200) {
        this.initialData = responseData.body;
        this.initialDataStatus = 'loaded';

        if (this.initialData.subscription_status !== 'active') {
          if (this.payPalSubscriptionsScriptStatus === 'idle') {
            this.$store.dispatch('loadPayPalSubscriptionsScript');
          } else if (this.payPalSubscriptionsScriptStatus === 'loaded') {
            nextTick(this.createPayPalButton);
          }
        }
      } else {
        this.initialDataStatus = 'error';
      }
    },
    promptToBecomeIPG() {
      this.$swal.fire({
        title: 'Get a Discount',
        text: 'If you register to become an Independent Professional Gamer for only '
        + `$${this.formatCurrency(this.initialData.pricing.ipg_registration)} `
        + 'then the cost of your subscription will be reduced to just '
        + `$${this.formatCurrency(this.initialData.pricing.customer_subscription_reduced)} `
        + 'per month!',
        showCancelButton: true,
        confirmButtonText: 'Register',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'settings_ipg' });
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
  .introduction {
    text-align: center;

    ul {
      display: table;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
    }
  }

  table {
    margin: 0 auto 2rem;
    border-collapse: collapse;
  }

  tr:nth-child(odd) {
    background-color: var(--gray-darker);
  }

  th,
  td {
    padding: 0.25rem;
  }

  th {
    text-align: right;
  }

  .paypal-content {
    margin: 0 auto 0.5rem;
    text-align: center;
  }

  #paypal-button-container {
    margin: 0 auto;
    width: 290px;
  }

  .spinner-template {
    display: none;
  }
</style>
