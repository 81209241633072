
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { IpgDataInterface, PricingInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    initialData: {} as IpgDataInterface,
    step: 0,
    stepData: {
      step1: { slug: 'electronic-signature-agreement', status: 'idle', content: '' },
      step2: { slug: 'income-disclosure', status: 'idle', content: '' },
      step3: { slug: 'policies-and-procedures', status: 'idle', content: '' },
      step4: { slug: 'compensation-plan', status: 'idle', content: '' },
      step5: { slug: 'independent-professional-gamer-application-and-agreement', status: 'idle', content: '' },
    } as {[key: string]: {[key: string]: string}},
    agreements: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
    } as {[key: string]: boolean},
    userDidNotAgree: false,
  }),
  computed: {
    ...mapState([
      'payPalIPGRegistrationFeeScriptStatus',
    ]),
  },
  watch: {
    payPalIPGRegistrationFeeScriptStatus() {
      if (this.payPalIPGRegistrationFeeScriptStatus === 'loaded') {
        this.createPayPalButton();
      }
    },
    userDidNotAgree() {
      if (this.userDidNotAgree) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    if (this.mode === 'onboard') {
      this.step = 1;
    }

    this.loadInitialData();
  },
  methods: {
    createPayPalButton() {
      window.paypalIPG.Buttons({
        createOrder: (data: any, actions: any) => actions.order.create({
          purchase_units: [{
            amount: {
              value: (this.initialData.pricing as PricingInterface).ipg_registration,
            },
            description: 'Independent Professional Gamer registration fee',
            custom_id: this.userData.id,
          }],
        }),
        onApprove: () => {
          this.$swal.fire({
            title: 'Almost Done!',
            html: '<p>Please allow 90 seconds for PayPal to confirm your IPG '
              + "registration with The Players Arena. Once that's complete, "
              + 'you will be able to use the business portal menu to access '
              + 'your eWallet, downline, and more!</p>'
              + '<p>This window will disappear after 90 seconds.</p>'
              + `${(this.$refs.spinnerTemplate as HTMLDivElement).innerHTML}`,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 90000,
            willClose: () => {
              const userData = JSON.parse(JSON.stringify(this.userData));

              let offerSelfSubscription = false;

              if (userData.signup_data !== undefined) {
                offerSelfSubscription = userData.signup_data.signup_type === 'both';
                delete userData.signup_data;
              }

              userData.ipg_status = 'active';
              this.$store.commit('userData', userData);

              if (offerSelfSubscription) {
                this.$swal.fire({
                  title: 'You Are Now an IPG',
                  text: 'Use the business portal menu to access your eWallet, '
                  + "downline, and more! Now that you're an IPG, let's finish "
                  + 'up by setting up your monthly subscription.',
                  showCancelButton: true,
                  confirmButtonText: 'Self-subscribe Now',
                  cancelButtonText: "I'll Do It Later",
                }).then(async (result: SweetAlertResult) => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: 'settings_subscription' });
                  } else if (this.mode === 'settings') {
                    this.initialData.ipg_status = 'active';
                  }
                });
              } else {
                this.initialData.ipg_status = 'active';
              }
            },
          });
        },
      }).render('#paypal-button-container');
    },
    formBackButtonClicked() {
      this.step -= 1;
    },
    formNextButtonClicked() {
      this.userDidNotAgree = false;

      this.$nextTick(() => {
        if (this.agreements[`step${this.step}`]) {
          this.step += 1;

          if (
            this.step < 6
            && !['loaded', 'loading'].includes(this.stepData[`step${this.step}`].status)
          ) {
            this.loadStepData();
          } else if (this.payPalIPGRegistrationFeeScriptStatus === 'idle') {
            this.$store.dispatch('loadPayPalIPGRegistrationFeeScript');
          } else if (this.payPalIPGRegistrationFeeScriptStatus === 'loaded') {
            this.$nextTick(this.createPayPalButton);
          }
        } else {
          this.userDidNotAgree = true;
        }
      });
    },
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_ipg/',
      });

      if (responseData.status === 200) {
        if (this.step === 1) {
          this.loadStepData();
        }

        this.initialData = responseData.body;
        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async loadStepData() {
      this.stepData[`step${this.step}`].status = 'loading';

      const responseData = await this.api({
        url: `documents/${this.stepData[`step${this.step}`].slug}/`,
      });

      if (responseData.status === 200) {
        this.stepData[`step${this.step}`].content = responseData.body.content;
        this.stepData[`step${this.step}`].status = 'loaded';
      } else {
        this.stepData[`step${this.step}`].status = 'error';
      }
    },
  },
});
