<template>
  <spinner
    v-if="initialDataStatus === 'loading'"
    preset="large"
  />

  <template v-else-if="initialDataStatus === 'loaded'">
    <alert
      v-if="formSuccess"
      variant="success"
    >
      Your changes have been saved.
    </alert>

    <template v-else>
      <alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </alert>

      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="psn_online_id">PlayStation Network online ID</label>
          <input
            id="psn_online_id"
            v-model="formFields.psn_online_id"
            :class="{ 'is-invalid': formErrors.psn_online_id !== undefined }"
            type="text"
            maxlength="16"
          >
          <div
            v-if="formErrors.psn_online_id !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.psn_online_id[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="xbox_gamertag">Xbox gamertag</label>
          <input
            id="xbox_gamertag"
            v-model="formFields.xbox_gamertag"
            :class="{ 'is-invalid': formErrors.xbox_gamertag !== undefined }"
            type="text"
            maxlength="16"
          >
          <div
            v-if="formErrors.xbox_gamertag !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.xbox_gamertag[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="nintendo_switch_nickname">Nintendo Switch nickname</label>
          <input
            id="nintendo_switch_nickname"
            v-model="formFields.nintendo_switch_nickname"
            :class="{ 'is-invalid': formErrors.nintendo_switch_nickname !== undefined }"
            type="text"
            maxlength="16"
          >
          <div
            v-if="formErrors.nintendo_switch_nickname !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.nintendo_switch_nickname[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="nintendo_switch_friend_code">Nintendo Switch friend code</label>
          <input
            id="nintendo_switch_friend_code"
            v-model="formFields.nintendo_switch_friend_code"
            :class="{ 'is-invalid': formErrors.nintendo_switch_friend_code !== undefined }"
            type="text"
            maxlength="17"
          >
          <div
            v-if="formErrors.nintendo_switch_friend_code !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.nintendo_switch_friend_code[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="blizzard_battletag">Blizzard BattleTag</label>
          <input
            id="blizzard_battletag"
            v-model="formFields.blizzard_battletag"
            :class="{ 'is-invalid': formErrors.blizzard_battletag !== undefined }"
            type="text"
            maxlength="16"
          >
          <div
            v-if="formErrors.blizzard_battletag !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.blizzard_battletag[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="epic_games_display_name">Epic Games display name</label>
          <input
            id="epic_games_display_name"
            v-model="formFields.epic_games_display_name"
            :class="{ 'is-invalid': formErrors.epic_games_display_name !== undefined }"
            type="text"
            maxlength="16"
          >
          <div
            v-if="formErrors.epic_games_display_name !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.epic_games_display_name[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="steam_account_name">Steam account name</label>
          <input
            id="steam_account_name"
            v-model="formFields.steam_account_name"
            :class="{ 'is-invalid': formErrors.steam_account_name !== undefined }"
            type="text"
            maxlength="64"
          >
          <div
            v-if="formErrors.steam_account_name !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.steam_account_name[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="twitch_username">Twitch username</label>
          <input
            id="twitch_username"
            v-model="formFields.twitch_username"
            :class="{ 'is-invalid': formErrors.twitch_username !== undefined }"
            type="text"
            maxlength="64"
          >
          <div
            v-if="formErrors.twitch_username !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.twitch_username[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="facebook_username">Facebook username</label>
          <input
            id="facebook_username"
            v-model="formFields.facebook_username"
            :class="{ 'is-invalid': formErrors.facebook_username !== undefined }"
            type="text"
            maxlength="50"
          >
          <div
            v-if="formErrors.facebook_username !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.facebook_username[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="twitter_username">Twitter username</label>
          <input
            id="twitter_username"
            v-model="formFields.twitter_username"
            :class="{ 'is-invalid': formErrors.twitter_username !== undefined }"
            type="text"
            maxlength="15"
          >
          <div
            v-if="formErrors.twitter_username !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.twitter_username[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="youtube_channel_id">YouTube channel ID</label>
          <input
            id="youtube_channel_id"
            v-model="formFields.youtube_channel_id"
            :class="{ 'is-invalid': formErrors.youtube_channel_id !== undefined }"
            type="text"
            minlength="24"
            maxlength="24"
            aria-describedby="youtube_channel_id_help"
          >
          <small
            id="youtube_channel_id_help"
            class="form-text"
          >
            This is 24 characters and begins with "UC", e.g.
            <span>youtube.com/channel/</span><strong>UCuJAPLfUYKpFmJJuuRjLndA</strong>
          </small>
          <div
            v-if="formErrors.youtube_channel_id !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.youtube_channel_id[0] }}
          </div>
        </div>

        <div :class="{ 'text-center': mode === 'onboard' }">
          <button
            v-if="mode === 'onboard'"
            type="button"
            class="btn btn-outline-light"
            :disabled="formSubmitting"
            @click="$emit('back')"
          >
            Back
          </button>

          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formSubmitting"
          >
            <template v-if="formSubmitting">
              Saving
              <spinner />
            </template>

            <template v-else>
              Save
            </template>
          </button>

          <button
            v-if="mode === 'onboard'"
            type="button"
            class="btn btn-outline-light"
            :disabled="formSubmitting"
            @click="$emit('done')"
          >
            Skip
          </button>
        </div>
      </form>
    </template>
  </template>

  <template v-else-if="initialDataStatus === 'error'">
    <alert variant="danger">
      An error occurred loading your data. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadInitialData"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  emits: [
    'back',
    'done',
  ],
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    formFields: {
      psn_online_id: '',
      xbox_gamertag: '',
      nintendo_switch_nickname: '',
      nintendo_switch_friend_code: '',
      blizzard_battletag: '',
      epic_games_display_name: '',
      steam_account_name: '',
      twitch_username: '',
      facebook_username: '',
      twitter_username: '',
      youtube_channel_id: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_third_party_accounts/',
      });

      if (responseData.status === 200) {
        const initialData = responseData.body;

        this.formFields.psn_online_id = initialData.psn_online_id;
        this.formFields.xbox_gamertag = initialData.xbox_gamertag;
        this.formFields.nintendo_switch_nickname = initialData.nintendo_switch_nickname;
        this.formFields.nintendo_switch_friend_code = initialData.nintendo_switch_friend_code;
        this.formFields.blizzard_battletag = initialData.blizzard_battletag;
        this.formFields.epic_games_display_name = initialData.epic_games_display_name;
        this.formFields.steam_account_name = initialData.steam_account_name;
        this.formFields.twitch_username = initialData.twitch_username;
        this.formFields.facebook_username = initialData.facebook_username;
        this.formFields.twitter_username = initialData.twitter_username;
        this.formFields.youtube_channel_id = initialData.youtube_channel_id;

        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/settings_third_party_accounts/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        if (this.mode === 'settings') {
          this.formSuccess = true;
        } else {
          this.$emit('done');
        }
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style scoped>
  form {
    margin: 0 auto 1rem;
    width: 290px;
  }

  #youtube_channel_id_help span {
    color: var(--gray-light);
  }
</style>
