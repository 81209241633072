
import { defineComponent, nextTick } from 'vue';

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  emits: [
    'back',
    'done',
  ],
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    formFields: {
      psn_online_id: '',
      xbox_gamertag: '',
      nintendo_switch_nickname: '',
      nintendo_switch_friend_code: '',
      blizzard_battletag: '',
      epic_games_display_name: '',
      steam_account_name: '',
      twitch_username: '',
      facebook_username: '',
      twitter_username: '',
      youtube_channel_id: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_third_party_accounts/',
      });

      if (responseData.status === 200) {
        const initialData = responseData.body;

        this.formFields.psn_online_id = initialData.psn_online_id;
        this.formFields.xbox_gamertag = initialData.xbox_gamertag;
        this.formFields.nintendo_switch_nickname = initialData.nintendo_switch_nickname;
        this.formFields.nintendo_switch_friend_code = initialData.nintendo_switch_friend_code;
        this.formFields.blizzard_battletag = initialData.blizzard_battletag;
        this.formFields.epic_games_display_name = initialData.epic_games_display_name;
        this.formFields.steam_account_name = initialData.steam_account_name;
        this.formFields.twitch_username = initialData.twitch_username;
        this.formFields.facebook_username = initialData.facebook_username;
        this.formFields.twitter_username = initialData.twitter_username;
        this.formFields.youtube_channel_id = initialData.youtube_channel_id;

        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/settings_third_party_accounts/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        if (this.mode === 'settings') {
          this.formSuccess = true;
        } else {
          this.$emit('done');
        }
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
