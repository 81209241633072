
import { defineComponent, nextTick } from 'vue';
// @ts-expect-error Could not find a declaration file for module 'vue-cropperjs'.
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import dataURItoFile from '@/methods/data_uri_to_file';

export default defineComponent({
  components: {
    VueCropper,
    TransitionSlide,
    ProfileImage,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  emits: [
    'done',
  ],
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    initialData: {
      profileImage: null,
      coverImage: null,
    },
    actions: {
      profileImage: 'do_nothing' as 'do_nothing' | 'change' | 'delete',
      coverImage: 'do_nothing' as 'do_nothing' | 'add' | 'change' | 'delete',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
    profileImageNonCroppedLoading: false,
    profileImageNonCropped: '',
    coverImageNonCroppedLoading: false,
    coverImageNonCropped: '',
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_images/',
      });

      if (responseData.status === 200) {
        this.initialData.profileImage = responseData.body.profile_image;
        this.initialData.coverImage = responseData.body.cover_image;
        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    profileImageCropperReady() {
      this.profileImageNonCroppedLoading = false;
    },
    coverImageCropperReady() {
      this.coverImageNonCroppedLoading = false;
    },
    setProfileImageNonCropped(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        this.profileImageNonCroppedLoading = true;

        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const imageBase64 = target.result as string;
          this.profileImageNonCropped = imageBase64;

          nextTick(() => {
            (this.$refs.profileImageCropper as any).replace(imageBase64);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.profileImageNonCropped = '';
      }
    },
    setCoverImageNonCropped(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        this.coverImageNonCroppedLoading = true;

        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const imageBase64 = target.result as string;
          this.coverImageNonCropped = imageBase64;

          nextTick(() => {
            (this.$refs.coverImageCropper as any).replace(imageBase64);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.coverImageNonCropped = '';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const formErrors = {} as Record<string, Array<string>>;

      if (this.actions.profileImage === 'change' && this.profileImageNonCropped === '') {
        formErrors.profile_image = ['Please choose a new profile image.'];
      }

      if (this.coverImageNonCropped === '') {
        if (this.actions.coverImage === 'add') {
          formErrors.cover_image = ['Please choose a cover image.'];
        } else if (this.actions.coverImage === 'change') {
          formErrors.cover_image = ['Please choose a new cover image.'];
        }
      }

      if (!this.isObjectEmpty(formErrors)) {
        this.formErrors = formErrors;
        this.formSubmitting = false;
        return;
      }

      const body = new FormData();

      if (this.actions.profileImage === 'change') {
        const imageType = this.profileImageNonCropped.substring(
          5,
          this.profileImageNonCropped.indexOf(';'),
        );

        const filename = `${Date.now()}.${imageType.slice(6)}`;

        body.append(
          'profile_image',
          dataURItoFile(
            (this.$refs.profileImageCropper as any).getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 256,
              height: 256,
            }).toDataURL(imageType),
            filename,
          ),
          filename,
        );
      } else if (this.actions.profileImage === 'delete') {
        body.append('delete_profile_image', 'true');
      }

      if (['add', 'change'].includes(this.actions.coverImage)) {
        const imageType = this.coverImageNonCropped.substring(
          5,
          this.coverImageNonCropped.indexOf(';'),
        );

        const filename = `${Date.now()}.${imageType.slice(6)}`;

        body.append(
          'cover_image',
          dataURItoFile(
            (this.$refs.coverImageCropper as any).getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 1920,
              height: 400,
            }).toDataURL(imageType),
            filename,
          ),
          filename,
        );
      } else if (this.actions.coverImage === 'delete') {
        body.append('delete_cover_image', 'true');
      }

      const responseData = await this.api({
        url: 'users/settings_images/',
        method: 'POST',
        body,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.$store.commit('userData', responseData.body);

        if (this.mode === 'settings') {
          this.formSuccess = true;
          this.$store.commit('userMenuProfileImageKeySuffixIncrement');
        } else {
          this.$emit('done');
        }
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
