<template>
  <spinner
    v-if="initialDataStatus === 'loading'"
    preset="large"
  />

  <template v-else-if="initialDataStatus === 'loaded'">
    <p
      v-if="initialData.ipg_status !== 'not_ipg'"
      class="text-center"
    >
      You are an Independent Professional Gamer. You may access your eWallet,
      downline, and more using the business portal menu.
    </p>

    <template v-else>
      <h2 v-if="step">
        <template v-if="step === 1">
          Electronic Signature Agreement
        </template>

        <template v-else-if="step === 2">
          Income Disclosure
        </template>

        <template v-else-if="step === 3">
          Policies and Procedures
        </template>

        <template v-else-if="step === 4">
          The Compensation Plan
        </template>

        <template v-else-if="step === 5">
          Application and Agreement
        </template>

        <template v-else-if="step === 6">
          Registration Fee
        </template>
      </h2>

      <div
        v-if="step === 0 && initialData.pricing"
        class="introduction"
      >
        <p>
          Register as an Independent Professional Gamer today for only
          ${{ formatCurrency(initialData.pricing.ipg_registration) }} and enjoy
          these great benefits:
        </p>

        <ul>
          <li>Mentor players into The Players Arena genealogy tree</li>
          <li>Sign up players as customers</li>
          <li>Get paid for each active customer or IPG under you</li>
          <li>Access to training and documents</li>
        </ul>

        <button
          type="button"
          class="btn btn-primary"
          @click="step = 1; loadStepData()"
        >
          Register Now
        </button>
      </div>

      <template v-else-if="step < 6">
        <div class="agreement-text subtle-scrollbar">
          <spinner
            v-if="stepData[`step${step}`].status === 'loading'"
            center
          />

          <template v-else-if="stepData[`step${step}`].status === 'error'">
            <alert variant="danger">
              Unable to load the text. Please check your connection and try again.
            </alert>

            <button
              type="button"
              class="btn btn-outline-primary"
              @click="loadStepData"
            >
              Try Again
            </button>
          </template>

          <div
            v-else-if="stepData[`step${step}`].status === 'loaded'"
            v-html="stepData[`step${step}`].content"
          />
        </div>

        <template v-if="stepData[`step${step}`].status === 'loaded'">
          <div class="form-check-wrapper">
            <div class="form-check">
              <template v-if="step === 1">
                <input
                  id="checkbox1"
                  v-model="agreements.step1"
                  type="checkbox"
                  class="form-check-input"
                  :class="{ 'is-invalid': userDidNotAgree }"
                >
                <label
                  for="checkbox1"
                  class="form-check-label"
                >
                  I have read the above electronic signature agreement and I
                  consent to the use of electronic records
                </label>
              </template>

              <template v-else-if="step === 2">
                <input
                  id="checkbox2"
                  v-model="agreements.step2"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  for="checkbox2"
                  class="form-check-label"
                >
                  I have read the above text about income disclosure and I agree
                </label>
              </template>

              <template v-else-if="step === 3">
                <input
                  id="checkbox3"
                  v-model="agreements.step3"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  for="checkbox3"
                  class="form-check-label"
                >
                  I have read the above text about policies and procedures and I
                  agree
                </label>
              </template>

              <template v-else-if="step === 4">
                <input
                  id="checkbox6"
                  v-model="agreements.step4"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  for="checkbox6"
                  class="form-check-label"
                >
                  I have read the above compensation plan and I agree
                </label>
              </template>

              <template v-else-if="step === 5">
                <input
                  id="checkbox7"
                  v-model="agreements.step5"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  for="checkbox7"
                  class="form-check-label"
                >
                  I have read the above application and agreement and I agree
                </label>
              </template>

              <div
                v-if="userDidNotAgree"
                class="invalid-feedback js-form-error"
              >
                You must agree before continuing.
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              v-if="step > 1"
              type="button"
              class="btn btn-outline-light"
              @click="formBackButtonClicked"
            >
              Back
            </button>

            <button
              type="button"
              class="btn btn-primary"
              @click="formNextButtonClicked"
            >
              Next
            </button>
          </div>
        </template>
      </template>

      <template v-else-if="step === 6">
        <p
          v-if="payPalIPGRegistrationFeeScriptStatus === 'loaded' && initialData.pricing"
          class="text-center"
        >
          Please use the PayPal button below to pay the registration fee of
          ${{ formatCurrency(initialData.pricing.ipg_registration) }}.
        </p>

        <spinner
          v-else-if="payPalIPGRegistrationFeeScriptStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="payPalIPGRegistrationFeeScriptStatus === 'error'">
          <alert variant="danger">
            An error occurred communicating with PayPal. Please check your
            connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="$store.dispatch('loadPayPalIPGRegistrationFeeScript')"
          >
            Try Again
          </button>
        </template>

        <div id="paypal-button-container" />

        <div
          ref="spinnerTemplate"
          class="spinner-template"
        >
          <spinner preset="large" />
        </div>

        <div class="buttons">
          <button
            type="button"
            class="btn btn-outline-light"
            @click="formBackButtonClicked"
          >
            Back
          </button>
        </div>
      </template>
    </template>
  </template>

  <template v-else-if="initialDataStatus === 'error'">
    <alert variant="danger">
      An error occurred loading your data. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadInitialData"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { IpgDataInterface, PricingInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    initialData: {} as IpgDataInterface,
    step: 0,
    stepData: {
      step1: { slug: 'electronic-signature-agreement', status: 'idle', content: '' },
      step2: { slug: 'income-disclosure', status: 'idle', content: '' },
      step3: { slug: 'policies-and-procedures', status: 'idle', content: '' },
      step4: { slug: 'compensation-plan', status: 'idle', content: '' },
      step5: { slug: 'independent-professional-gamer-application-and-agreement', status: 'idle', content: '' },
    } as {[key: string]: {[key: string]: string}},
    agreements: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
    } as {[key: string]: boolean},
    userDidNotAgree: false,
  }),
  computed: {
    ...mapState([
      'payPalIPGRegistrationFeeScriptStatus',
    ]),
  },
  watch: {
    payPalIPGRegistrationFeeScriptStatus() {
      if (this.payPalIPGRegistrationFeeScriptStatus === 'loaded') {
        this.createPayPalButton();
      }
    },
    userDidNotAgree() {
      if (this.userDidNotAgree) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    if (this.mode === 'onboard') {
      this.step = 1;
    }

    this.loadInitialData();
  },
  methods: {
    createPayPalButton() {
      window.paypalIPG.Buttons({
        createOrder: (data: any, actions: any) => actions.order.create({
          purchase_units: [{
            amount: {
              value: (this.initialData.pricing as PricingInterface).ipg_registration,
            },
            description: 'Independent Professional Gamer registration fee',
            custom_id: this.userData.id,
          }],
        }),
        onApprove: () => {
          this.$swal.fire({
            title: 'Almost Done!',
            html: '<p>Please allow 90 seconds for PayPal to confirm your IPG '
              + "registration with The Players Arena. Once that's complete, "
              + 'you will be able to use the business portal menu to access '
              + 'your eWallet, downline, and more!</p>'
              + '<p>This window will disappear after 90 seconds.</p>'
              + `${(this.$refs.spinnerTemplate as HTMLDivElement).innerHTML}`,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 90000,
            willClose: () => {
              const userData = JSON.parse(JSON.stringify(this.userData));

              let offerSelfSubscription = false;

              if (userData.signup_data !== undefined) {
                offerSelfSubscription = userData.signup_data.signup_type === 'both';
                delete userData.signup_data;
              }

              userData.ipg_status = 'active';
              this.$store.commit('userData', userData);

              if (offerSelfSubscription) {
                this.$swal.fire({
                  title: 'You Are Now an IPG',
                  text: 'Use the business portal menu to access your eWallet, '
                  + "downline, and more! Now that you're an IPG, let's finish "
                  + 'up by setting up your monthly subscription.',
                  showCancelButton: true,
                  confirmButtonText: 'Self-subscribe Now',
                  cancelButtonText: "I'll Do It Later",
                }).then(async (result: SweetAlertResult) => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: 'settings_subscription' });
                  } else if (this.mode === 'settings') {
                    this.initialData.ipg_status = 'active';
                  }
                });
              } else {
                this.initialData.ipg_status = 'active';
              }
            },
          });
        },
      }).render('#paypal-button-container');
    },
    formBackButtonClicked() {
      this.step -= 1;
    },
    formNextButtonClicked() {
      this.userDidNotAgree = false;

      this.$nextTick(() => {
        if (this.agreements[`step${this.step}`]) {
          this.step += 1;

          if (
            this.step < 6
            && !['loaded', 'loading'].includes(this.stepData[`step${this.step}`].status)
          ) {
            this.loadStepData();
          } else if (this.payPalIPGRegistrationFeeScriptStatus === 'idle') {
            this.$store.dispatch('loadPayPalIPGRegistrationFeeScript');
          } else if (this.payPalIPGRegistrationFeeScriptStatus === 'loaded') {
            this.$nextTick(this.createPayPalButton);
          }
        } else {
          this.userDidNotAgree = true;
        }
      });
    },
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_ipg/',
      });

      if (responseData.status === 200) {
        if (this.step === 1) {
          this.loadStepData();
        }

        this.initialData = responseData.body;
        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    async loadStepData() {
      this.stepData[`step${this.step}`].status = 'loading';

      const responseData = await this.api({
        url: `documents/${this.stepData[`step${this.step}`].slug}/`,
      });

      if (responseData.status === 200) {
        this.stepData[`step${this.step}`].content = responseData.body.content;
        this.stepData[`step${this.step}`].status = 'loaded';
      } else {
        this.stepData[`step${this.step}`].status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .introduction {
    text-align: center;

    ul {
      display: table;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
    }
  }

  .agreement-text {
    margin: 0 auto 1rem;
    padding: 0.5rem;
    width: 500px;
    max-width: 100%;
    height: 37vh;
    background-color: var(--gray-dark);
    border: 1px solid var(--gray-darker);
    border-radius: 0.25rem;
    overflow-y: auto;

    :deep(img) {
      max-width: 100%;
      height: auto;
    }

    div :deep(p:last-child) {
      margin-bottom: 0;
    }
  }

  .form-check-wrapper {
    display: flex;
    justify-content: center;
  }

  .buttons {
    margin-top: 0.5rem;
    text-align: center;
  }

  #paypal-button-container {
    margin: 0 auto;
    width: 290px;
  }

  .spinner-template {
    display: none;
  }
</style>
