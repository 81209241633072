<template>
  <spinner
    v-if="initialDataStatus === 'loading'"
    preset="large"
  />

  <template v-else-if="initialDataStatus === 'loaded'">
    <alert
      v-if="formSuccess"
      variant="success"
    >
      Your changes have been saved.
    </alert>

    <template v-else>
      <alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </alert>

      <div class="cards-and-button">
        <div class="cards">
          <div class="card">
            <h2>Profile Image</h2>

            <div>
              <profile-image
                class="current-profile-image"
                :size="128"
                bordered
              />
            </div>

            <transition-slide mode="out-in">
              <div v-if="actions.profileImage === 'do_nothing'">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  aria-label="Change Profile Image"
                  @click="actions.profileImage = 'change'"
                >
                  Change
                </button>

                <button
                  v-if="initialData.profileImage !== null"
                  type="button"
                  class="btn btn-outline-danger"
                  aria-label="Delete Profile Image"
                  @click="actions.profileImage = 'delete'"
                >
                  Delete
                </button>
              </div>

              <div v-else-if="actions.profileImage === 'change'">
                <div class="form-group">
                  <label for="new_profile_image">New profile image</label>
                  <input
                    id="new_profile_image"
                    :class="{ 'is-invalid': formErrors.profile_image !== undefined }"
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    @change="setProfileImageNonCropped"
                  >
                  <div
                    v-if="formErrors.profile_image !== undefined"
                    class="invalid-feedback js-form-error"
                  >
                    {{ formErrors.profile_image[0] }}
                  </div>
                </div>

                <div
                  v-if="profileImageNonCroppedLoading || profileImageNonCropped"
                  class="cropper-message"
                >
                  <template v-if="profileImageNonCroppedLoading">
                    Loading...
                  </template>

                  <template v-else>
                    Use the blue outline below to specify how you would like
                    your profile image to be cropped. When you're ready, click
                    the "Save" button below.
                  </template>
                </div>

                <vue-cropper
                  v-if="profileImageNonCropped"
                  ref="profileImageCropper"
                  :view-mode="2"
                  :aspect-ratio="1"
                  :guides="false"
                  :background="false"
                  :auto-crop-area="0.5"
                  :zoomable="false"
                  :src="profileImageNonCropped"
                  :ready="profileImageCropperReady"
                />

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="actions.profileImage = 'do_nothing'"
                >
                  Never mind, I don't want to change my profile image
                </button>
              </div>

              <div v-else-if="actions.profileImage === 'delete'">
                <alert variant="danger">
                  Your profile image will be deleted.
                </alert>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="actions.profileImage = 'do_nothing'"
                >
                  Never mind, I don't want to delete my profile image
                </button>
              </div>
            </transition-slide>
          </div>

          <div class="card">
            <h2>Cover Image</h2>

            <div class="current-cover-image-or-message">
              <img
                v-if="initialData.coverImage"
                :src="initialData.coverImage"
                alt
                class="img-fluid"
              >

              <template v-else>
                If you add a cover image, it will appear at the top of your
                profile.
              </template>
            </div>

            <transition-slide mode="out-in">
              <div v-if="actions.coverImage === 'do_nothing'">
                <button
                  v-if="initialData.coverImage === null"
                  type="button"
                  class="btn btn-outline-primary"
                  aria-label="Add Cover Image"
                  @click="actions.coverImage = 'add'"
                >
                  Add
                </button>

                <template v-else>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    aria-label="Change Cover Image"
                    @click="actions.coverImage = 'change'"
                  >
                    Change
                  </button>

                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    aria-label="Delete Cover Image"
                    @click="actions.coverImage = 'delete'"
                  >
                    Delete
                  </button>
                </template>
              </div>

              <div v-else-if="['add', 'change'].includes(actions.coverImage)">
                <div class="form-group">
                  <label for="new_cover_image">New cover image</label>
                  <input
                    id="new_cover_image"
                    :class="{ 'is-invalid': formErrors.cover_image !== undefined }"
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    @change="setCoverImageNonCropped"
                  >
                  <div
                    v-if="formErrors.cover_image !== undefined"
                    class="invalid-feedback js-form-error"
                  >
                    {{ formErrors.cover_image[0] }}
                  </div>
                </div>

                <div
                  v-if="coverImageNonCroppedLoading || coverImageNonCropped"
                  class="cropper-message"
                >
                  <template v-if="coverImageNonCroppedLoading">
                    Loading...
                  </template>

                  <template v-else>
                    Use the blue outline below to specify how you would like
                    your cover image to be cropped. When you're ready, click
                    the "Save" button below.
                  </template>
                </div>

                <vue-cropper
                  v-if="coverImageNonCropped"
                  ref="coverImageCropper"
                  :view-mode="2"
                  :aspect-ratio="4.8"
                  :guides="false"
                  :background="false"
                  :auto-crop-area="0.5"
                  :zoomable="false"
                  :src="coverImageNonCropped"
                  :ready="coverImageCropperReady"
                />

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="actions.coverImage = 'do_nothing'"
                >
                  Never mind, I don't want to

                  <template v-if="actions.coverImage === 'add'">
                    add a
                  </template>

                  <template v-else>
                    change my
                  </template>

                  cover image
                </button>
              </div>

              <div v-else-if="actions.coverImage === 'delete'">
                <alert variant="danger">
                  Your cover image will be deleted.
                </alert>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="actions.coverImage = 'do_nothing'"
                >
                  Never mind, I don't want to delete my cover image
                </button>
              </div>
            </transition-slide>
          </div>
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formSubmitting"
            @click="submitForm"
          >
            <template v-if="formSubmitting">
              Saving
              <spinner />
            </template>

            <template v-else>
              Save
            </template>
          </button>

          <button
            v-if="mode === 'onboard'"
            type="button"
            class="btn btn-outline-light"
            :disabled="formSubmitting"
            @click="$emit('done')"
          >
            Skip
          </button>
        </div>
      </div>
    </template>
  </template>

  <template v-else-if="initialDataStatus === 'error'">
    <alert variant="danger">
      An error occurred loading your data. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadInitialData"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
// @ts-expect-error Could not find a declaration file for module 'vue-cropperjs'.
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import dataURItoFile from '@/methods/data_uri_to_file';

export default defineComponent({
  components: {
    VueCropper,
    TransitionSlide,
    ProfileImage,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  emits: [
    'done',
  ],
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    initialData: {
      profileImage: null,
      coverImage: null,
    },
    actions: {
      profileImage: 'do_nothing' as 'do_nothing' | 'change' | 'delete',
      coverImage: 'do_nothing' as 'do_nothing' | 'add' | 'change' | 'delete',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
    profileImageNonCroppedLoading: false,
    profileImageNonCropped: '',
    coverImageNonCroppedLoading: false,
    coverImageNonCropped: '',
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_images/',
      });

      if (responseData.status === 200) {
        this.initialData.profileImage = responseData.body.profile_image;
        this.initialData.coverImage = responseData.body.cover_image;
        this.initialDataStatus = 'loaded';
      } else {
        this.initialDataStatus = 'error';
      }
    },
    profileImageCropperReady() {
      this.profileImageNonCroppedLoading = false;
    },
    coverImageCropperReady() {
      this.coverImageNonCroppedLoading = false;
    },
    setProfileImageNonCropped(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        this.profileImageNonCroppedLoading = true;

        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const imageBase64 = target.result as string;
          this.profileImageNonCropped = imageBase64;

          nextTick(() => {
            (this.$refs.profileImageCropper as any).replace(imageBase64);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.profileImageNonCropped = '';
      }
    },
    setCoverImageNonCropped(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        this.coverImageNonCroppedLoading = true;

        const reader = new FileReader();

        reader.onload = (event) => {
          const target = event.target as FileReader;
          const imageBase64 = target.result as string;
          this.coverImageNonCropped = imageBase64;

          nextTick(() => {
            (this.$refs.coverImageCropper as any).replace(imageBase64);
          });
        };

        reader.readAsDataURL(file);
      } else {
        this.coverImageNonCropped = '';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const formErrors = {} as Record<string, Array<string>>;

      if (this.actions.profileImage === 'change' && this.profileImageNonCropped === '') {
        formErrors.profile_image = ['Please choose a new profile image.'];
      }

      if (this.coverImageNonCropped === '') {
        if (this.actions.coverImage === 'add') {
          formErrors.cover_image = ['Please choose a cover image.'];
        } else if (this.actions.coverImage === 'change') {
          formErrors.cover_image = ['Please choose a new cover image.'];
        }
      }

      if (!this.isObjectEmpty(formErrors)) {
        this.formErrors = formErrors;
        this.formSubmitting = false;
        return;
      }

      const body = new FormData();

      if (this.actions.profileImage === 'change') {
        const imageType = this.profileImageNonCropped.substring(
          5,
          this.profileImageNonCropped.indexOf(';'),
        );

        const filename = `${Date.now()}.${imageType.slice(6)}`;

        body.append(
          'profile_image',
          dataURItoFile(
            (this.$refs.profileImageCropper as any).getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 256,
              height: 256,
            }).toDataURL(imageType),
            filename,
          ),
          filename,
        );
      } else if (this.actions.profileImage === 'delete') {
        body.append('delete_profile_image', 'true');
      }

      if (['add', 'change'].includes(this.actions.coverImage)) {
        const imageType = this.coverImageNonCropped.substring(
          5,
          this.coverImageNonCropped.indexOf(';'),
        );

        const filename = `${Date.now()}.${imageType.slice(6)}`;

        body.append(
          'cover_image',
          dataURItoFile(
            (this.$refs.coverImageCropper as any).getCroppedCanvas({
              imageSmoothingQuality: 'high',
              width: 1920,
              height: 400,
            }).toDataURL(imageType),
            filename,
          ),
          filename,
        );
      } else if (this.actions.coverImage === 'delete') {
        body.append('delete_cover_image', 'true');
      }

      const responseData = await this.api({
        url: 'users/settings_images/',
        method: 'POST',
        body,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.$store.commit('userData', responseData.body);

        if (this.mode === 'settings') {
          this.formSuccess = true;
          this.$store.commit('userMenuProfileImageKeySuffixIncrement');
        } else {
          this.$emit('done');
        }
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .cards-and-button {
    margin: 0 auto;
    max-width: 480px;
  }

  .cards {
    display: grid;
    grid-gap: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .current-profile-image {
    margin-bottom: 1rem;
  }

  .cropper-message,
  :deep(.cropper-container),
  .current-cover-image-or-message {
    margin-bottom: 1rem;
  }

  input[type="file"] {
    margin: 0 auto;
  }

  @media (min-width: 1280px) {
    .cards-and-button {
      max-width: 980px;
    }

    .cards {
      grid-template-columns: 1fr 1fr;

      .card {
        min-width: 0;
      }
    }

    .current-cover-image-or-message {
      height: 144px;
    }
  }
</style>
