
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick } from 'vue';
import { mapState } from 'vuex';
import { SubscriptionDataInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: false,
      default: 'settings',
      validator: (value: string) => [
        'settings',
        'onboard',
      ].includes(value),
    },
  },
  emits: [
    'back',
  ],
  data: () => ({
    initialDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    initialData: {} as SubscriptionDataInterface,
  }),
  computed: {
    ...mapState([
      'payPalSubscriptionsScriptStatus',
    ]),
  },
  watch: {
    payPalSubscriptionsScriptStatus() {
      if (this.payPalSubscriptionsScriptStatus === 'loaded') {
        this.createPayPalButton();
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    createPayPalButton() {
      let planId = '';

      if (this.initialData.ipg_status === 'not_ipg') {
        planId = process.env.VUE_APP_PAYPAL_SUBSCRIPTION_PLAN_ID_CUSTOMER;
      } else {
        planId = process.env.VUE_APP_PAYPAL_SUBSCRIPTION_PLAN_ID_IPG;
      }

      window.paypal.Buttons({
        createSubscription: (data: any, actions: any) => actions.subscription.create({
          plan_id: planId,
          custom_id: this.userData.id,
        }),
        onApprove: () => {
          this.$swal.fire({
            title: 'Almost Done!',
            html: '<p>Please allow 90 seconds for PayPal to confirm your '
              + "subscription status with The Players Arena. Once that's "
              + 'complete, you will be able to create posts, create or join '
              + 'teams, let others know what games you play, and more!</p>'
              + '<p>This window will disappear after 90 seconds.</p>'
              + `${(this.$refs.spinnerTemplate as HTMLDivElement).innerHTML}`,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 90000,
            willClose: () => {
              const userData = JSON.parse(JSON.stringify(this.userData));
              delete userData.signup_data;
              userData.subscription_status = 'active';
              this.$store.commit('userData', userData);

              if (this.mode === 'settings') {
                this.loadInitialData();
              }
            },
          });
        },
      }).render('#paypal-button-container');
    },
    async loadInitialData() {
      this.initialDataStatus = 'loading';

      const responseData = await this.api({
        url: 'users/settings_subscription/',
      });

      if (responseData.status === 200) {
        this.initialData = responseData.body;
        this.initialDataStatus = 'loaded';

        if (this.initialData.subscription_status !== 'active') {
          if (this.payPalSubscriptionsScriptStatus === 'idle') {
            this.$store.dispatch('loadPayPalSubscriptionsScript');
          } else if (this.payPalSubscriptionsScriptStatus === 'loaded') {
            nextTick(this.createPayPalButton);
          }
        }
      } else {
        this.initialDataStatus = 'error';
      }
    },
    promptToBecomeIPG() {
      this.$swal.fire({
        title: 'Get a Discount',
        text: 'If you register to become an Independent Professional Gamer for only '
        + `$${this.formatCurrency(this.initialData.pricing.ipg_registration)} `
        + 'then the cost of your subscription will be reduced to just '
        + `$${this.formatCurrency(this.initialData.pricing.customer_subscription_reduced)} `
        + 'per month!',
        showCancelButton: true,
        confirmButtonText: 'Register',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'settings_ipg' });
        }
      });
    },
  },
});
